@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom styles to hide the vertical scrollbar */
body {
  margin: 0;
  @apply font-sans;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Hide vertical scrollbar but allow scrolling */
  overflow-y: scroll; /* Ensures the element can still be scrolled */
}

::-webkit-scrollbar {
  display: none; /* Hide the scrollbar for WebKit browsers */
}

code {
  @apply font-code;
}

@keyframes dot {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  50% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-dot {
  animation: dot 2s infinite;
}
